<template>
  <div class="event-template-card">
    <img class="card-image" :src="data.img" alt="">

    <div style="padding: 16px">
      <p class="card-title">{{ data.title }}</p>
      <div class="card-description">
        {{ data.description }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'BeautyAIEventTemplateCard',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props) {
    return {}
  },
})
</script>

<style lang="postcss" scoped>
.event-template-card {
  @apply rounded-[8px] overflow-hidden bg-white cursor-pointer;
  @apply flex flex-col;
  @apply w-[240px] h-[286px];
}

.selected {
  @apply border-[4px] border-primary-100;
}

.card-image {
    @apply h-[160px];
}

.card-title {
    @apply font-bold text-lg;
}

.card-description {
    @apply text-gray-80 text-sub;
}
</style>
