<template>
  <div class="">
    <p class="font-bold ">根據您的選擇，AI 智能建議的行銷策略為：</p>

    <div class="title-card">
      <p class="text-[24px]">{{ contextData.strategy }}</p>

      <div class="text-sm flex items-center gap-[4px]">
        <SVGIcon
          icon="common-stars"
          stroke="transparent"
          width="14px"
          height="14px"
          fill="white"
        />
        <p>AI generated</p>
      </div>
    </div>

    <div class="mb-[30px] text-gray-80">
      <typewriter
        :type-interval="50"
      >
        您希望以「{{ contextData.strategy }}」鼓勵「{{ contextData.tag || '所有' }}」的顧客進型消費，以達到「{{ contextData.target }}」。預約消費禮通過提供額外價值，降低顧客首次消費的心理障礙，提升顧客對品牌的認識和好感，從而有效地鼓勵未曾互動消費的顧客進行消費，達到增加業績的效果。建議到「事件行銷列表」新增活動，設定活動資訊、事件設定、和行銷內容設定。
      </typewriter>
    </div>

    <!-- <textarea v-model="text" class="w-full" /> -->

    <div ref="resultRef" class="result-container">
      <div class="flex justify-between items-center">
        <p class="text-xl font-medium pb-[16px] ">事件行銷設定建議</p>
        <el-button v-if="show.exportImage" class="download-btn" plain :loading="loading.exportImage" @click="toImage">
          下載設定截圖
          <i class="el-icon-download" />
        </el-button>
      </div>

      <div class="result-content" :style="expand ? `height: auto;`: `height: 128px;`">
        <div
          class="markdown-body"
          style="font-size: 14px; font-family: 'Noto Sans TC', PingFang SC, Microsoft JhengHei, '微軟正黑體';"
          :style="expand ? `padding-bottom: 32px;`: ``"
        >
          <typewriter :type-interval="50">
            <div v-html="displayContent" />
          </typewriter>
        </div>
        <div v-if="show.exportImage" class="view-more" @click="expand = !expand">
          <span>{{ expand ? 'view less' : 'view more' }}</span>
          <span v-if="expand" class="material-icons">keyboard_arrow_up</span>
          <span v-if="!expand" class="material-icons">keyboard_arrow_down</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref, nextTick, reactive, onMounted } from 'vue'
import { marked } from 'marked'
import { get } from 'lodash'
import { toPng } from 'html-to-image'
import Typewriter from 'typewriter-vue'
import dayjs from '@/lib/dayjs'
import { formatDate } from '@/utils/date'

export default defineComponent({
  name: 'BeautyAIEventTemplateGenerateResult',
  components: {
    Typewriter,
  },
  props: {
    contextData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props) {
    const resultRef = ref(null)
    const loading = reactive({
      exportImage: false,
    })
    const show = reactive({
      exportImage: false,
    })
    // 推薦禮
    const template1 = '- 建立事件行銷：獎勵舊會員\n\n  - 事件設定\n\n    - 事件類型：推薦禮-獎勵舊會員\n\n    - 事件範本：累積推薦滿人數\n\n    - 累積計算方式：每次累積\n\n      累積推薦人數設置：累積推薦1 人\n\n- 行銷內容設定\n\n  - 選擇行銷項目：回饋金 （或者其它您偏好的回饋項目）\n\n  - 行銷項目總數：無上限（若您有行銷預算上限，請再此填寫您的預算）\n\n  - 每次達成事件發送的行銷項目數量：50 (此為您要發給客戶的獎勵額度）\n  - 發送時間：當下發送\n\n- 建立事件行銷：獎勵新會員\n\n  - 事件設定\n\n    - 事件類型：推薦禮-獎勵新會員\n\n    - 事件範本：完成註冊與 LINE 綁定\n\n    - 累積計算方式：總累積\n\n  - 行銷內容設定\n\n    - 選擇行銷項目：回饋金 （或者其它您偏好的回饋項目）\n\n    - 行銷項目總數：無上限（若您有行銷預算上限，請再此填寫您的預算）\n\n    - 每次達成事件發送的行銷項目數量：50 (此為您要發給客戶的獎勵額度）\n\n    - 發送時間：當下發送\n\n- 建立分群推播\n\n  - 訊息\n\n    - 您的活動內容，撰寫主要優惠、時間、條件，讓您的客戶知道有這個活動\n\n  - 對象\n\n    - 所有會員\n\n  - 時間\n\n    - 立即發送（或者您偏好、預計上限的活動時間）'
    // 註冊禮
    const template2 = '- 建立事件行銷\n\n  - 事件設定\n\n    - 事件類型：註冊禮\n\n    - 事件範本：完成綁定line\n\n    - 消費滿額設置\n\n      超出指定件數 單筆消費滿 1 件\n\n- 行銷內容設定\n\n  - 領取次數規則：單一會員只能領取1次\n\n  - 選擇行銷項目：回饋金 （或者其它您偏好的回饋項目）\n\n  - 行銷項目總數：無上限（若您有行銷預算上限，請再此填寫您的預算）\n\n  - 每次達成事件發送的行銷項目數量：50 (此為您要發給客戶的獎勵額度）\n\n  - 發送時間：當下發送'
    // 等級禮
    const template3 = '- 建立事件行銷\n\n  - 事件設定\n\n    - 事件類型：等級禮\n\n    - 事件範本：升等\n\n  - 行銷內容設定\n\n    - 領取次數規則：單一會員只能領取1次\n\n    - 選擇行銷項目：回饋金 （或者其它您偏好的回饋項目）\n\n    - 行銷項目總數：無上限（若您有行銷預算上限，請再此填寫您的預算）\n\n    - 每次達成事件發送的行銷項目數量：100 (此為您要發給客戶的獎勵額度）\n\n    - 發送時間：當下發送\n\n- 建立分群推播\n\n  - 訊息\n\n    - 您的活動內容，撰寫主要優惠、時間、條件，讓您的客戶知道有這個活動\n\n  - 對象\n\n    - 所有會員\n\n  - 時間\n\n    - 立即發送（或者您偏好、預計上限的活動時間）'
    // 首次體驗禮
    const template4 = '- 建立事件行銷\n\n  - 事件設定\n\n    - 事件類型：預約消費禮\n\n    - 事件範本：首次完成訂單滿件數\n\n    - 消費滿額設置\n\n      超出指定件數 單筆消費滿 1 件\n\n  - 進階條件設定\n\n      發放對象指定條件(同時符合)：未曾互動消費\n\n  - 行銷內容設定\n\n    - 領取次數規則：單一會員只能領取1次\n\n    - 選擇行銷項目：回饋金 （或者其它您偏好的回饋項目）\n\n    - 行銷項目總數：無上限（若您有行銷預算上限，請再此填寫您的預算）\n\n    - 每次達成事件發送的行銷項目數量：50 (此為您要發給客戶的獎勵額度）\n\n    - 發送時間：當下發送\n\n- 建立分群推播\n\n  - 訊息\n\n    - 您的活動內容，撰寫主要優惠、時間、條件，讓您的客戶知道有這個活動\n\n  - 對象\n\n    - 進階標籤會員：未曾互動消費\n\n  - 時間\n\n    - 立即發送（或者您偏好、預計上限的活動時間）'
    // 累積消費獎勵
    const template5 = '- 建立事件行銷\n\n  - 事件設定\n\n    - 事件類型：預約消費禮\n\n    - 事件範本：完成累積訂單滿額\n\n    - 累積計算方式：每次累積\n\n    - 訂單累積金額：5000 (或者其它您偏好的金額）\n\n  - 行銷內容設定\n\n    - 領取次數規則：單一會員可重複領取\n\n    - 選擇行銷項目：回饋金 （或者其它您偏好的回饋項目）\n\n    - 行銷項目總數：無上限（若您有行銷預算上限，請再此填寫您的預算）\n\n    - 每次達成事件發送的行銷項目數量：500 (此為您要發給客戶的獎勵額度）\n\n    - 發送時間：當下發送\n\n- 建立分群推播\n\n  - 訊息\n\n    - 您的活動內容，撰寫主要優惠、時間、條件，讓您的客戶知道有這個活動\n\n  - 對象\n\n    - 所有會員\n\n  - 時間\n\n    - 立即發送（或者您偏好、預計上限的活動時間）'
    // 預約消費禮 - 滿額贈
    const template6 = '- 建立事件行銷\n\n  - 事件設定\n\n    - 事件類型：預約消費禮\n\n    - 事件範本：完成單筆訂單滿額\n\n    - 累積計算方式：每次累積\n\n    - 單筆消費規則：單筆符合消費條件只計算 1 次\n\n    - 訂單累積金額：2000 (或者其它您偏好的金額）\n\n  - 行銷內容設定\n\n    - 領取次數規則：單一會員可重複領取\n\n    - 選擇行銷項目：回饋金 （或者其它您偏好的回饋項目）\n\n    - 行銷項目總數：無上限（若您有行銷預算上限，請再此填寫您的預算）\n\n    - 每次達成事件發送的行銷項目數量：200 (此為您要發給客戶的獎勵額度）\n\n    - 發送時間：當下發送\n\n- 建立分群推播\n\n  - 訊息\n\n    - 您的活動內容，撰寫主要優惠、時間、條件，讓您的客戶知道有這個活動\n\n  - 對象\n\n    - 所有會員\n\n  - 時間\n\n    - 立即發送（或者您偏好、預計上限的活動時間）'
    // 預約消費禮 - 消費即贈
    const template7 = '- 建立事件行銷\n\n  - 事件設定\n\n    - 事件類型：預約消費禮\n\n    - 事件範本：完成單筆訂單滿件數\n\n    - 累積計算方式：每次累積\n\n    - 單筆消費規則：單筆符合消費條件只計算 1 次\n\n  - 消費滿額設置：\n\n    超出指定件數 單筆消費滿 1 件\n\n  - 行銷內容設定\n\n    - 領取次數規則：單一會員可重複領取\n\n    - 選擇行銷項目：回饋金 （或者其它您偏好的回饋項目）\n\n    - 行銷項目總數：無上限（若您有行銷預算上限，請再此填寫您的預算）\n\n    - 每次達成事件發送的行銷項目數量：100 (此為您要發給客戶的獎勵額度）\n\n    - 發送時間：當下發送\n\n- 建立分群推播\n\n  - 訊息\n\n    - 您的活動內容，撰寫主要優惠、時間、條件，讓您的客戶知道有這個活動\n\n  - 對象\n\n    - 所有會員\n\n  - 時間\n\n    - 立即發送（或者您偏好、預計上限的活動時間）'
    // 預約消費禮 - 消費指定項目即贈
    const template8 = '- 建立事件行銷\n\n  - 事件設定\n\n    - 事件類型：預約消費禮\n\n    - 事件範本：指定完成單筆訂單滿件數\n\n    - 累積計算方式：每次累積\n\n    - 單筆消費規則：單筆符合消費條件可重複計算\n\n    - 選擇指定預約項目：選擇您指定的服務項目\n\n    - 消費滿額設置：\n\n      超出指定件數 單筆消費滿 1 件\n\n  - 行銷內容設定\n\n    - 領取次數規則：單一會員可重複領取\n\n    - 選擇行銷項目：回饋金 （或者其它您偏好的回饋項目）\n\n    - 行銷項目總數：無上限（若您有行銷預算上限，請再此填寫您的預算）\n\n    - 每次達成事件發送的行銷項目數量：100 (此為您要發給客戶的獎勵額度）\n\n    - 發送時間：當下發送\n\n- 建立分群推播\n\n  - 訊息\n\n    - 您的活動內容，撰寫主要優惠、時間、條件，讓您的客戶知道有這個活動\n\n  - 對象\n\n    - 所有會員\n\n  - 時間\n\n    - 立即發送（或者您偏好、預計上限的活動時間）'

    const templateDict = {
      好友推薦禮: template1,
      註冊禮: template2,
      首次體驗禮: template4,
      等級禮: template3,
      生日禮: template5,
      累積消費獎勵: template5,
      滿額贈: template6,
      消費即贈: template7,
      消費指定項目即贈: template8,
    }

    const text = ref('')
    const expand = ref(true)

    const displayContent = computed(() => {
      if (props.contextData.strategy === '預約消費禮 - 首次體驗禮') {
        return marked.parse(template4, { sanitize: true })
      }

      return marked.parse(get(templateDict, props.contextData.strategy), { sanitize: true })
    })

    const toImage = async () => {
      if (loading.exportImage) return
      loading.exportImage = true
      expand.value = true
      await nextTick()
      const nodeFilter = (node) => {
        const classList = node.classList ? Array.from(node.classList) : []
        if (classList.includes('download-btn')) return false
        if (classList.includes('view-more')) return false
        return node
      }
      await toPng(resultRef.value, { filter: nodeFilter }).then((dataUrl) => {
        const link = document.createElement('a')
        link.download = `行銷策略建議_${formatDate()}.png`
        link.href = dataUrl
        link.click()
        loading.exportImage = false
      })
    }

    onMounted(async () => {
      (async () => {
        await new Promise((resolve) => setTimeout(resolve, 23000))
        show.exportImage = true
      })()
    })

    return { displayContent, expand, text, resultRef, toImage, loading, show }
  },
})

</script>

<style lang="postcss" scoped>
.result-container {
    @apply bg-white rounded-[12px] py-[16px] px-[20px];
}

.view-more {
    @apply absolute bottom-0 right-0 left-0 cursor-pointer border-gray-60 border-t;
    @apply  pt-[12px] bg-white;
    @apply flex items-center gap-[8px];
}

.title-card {
  @apply px-[20px] py-[8px] rounded-[8px] w-[fit-content] text-white;
  background: linear-gradient(90deg, #48B2FF 0%, #4175F5 100%);
  @apply flex flex-col gap-[4px] my-[16px];
}

.result-content {
    @apply relative overflow-hidden;
    @apply  break-all;
}

</style>

<style>
.content *:last-child::after {
  font-size: 0 !important;
  animation: none !important;
}
</style>
