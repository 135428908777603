var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('div',{staticClass:"flex justify-between items-center font-medium text-lg"},[_c('p',[_vm._v("Step"+_vm._s(_vm.curStep)+"："+_vm._s(_vm.stepsConfig[_vm.curStep - 1].label))]),_c('p',[_vm._v(_vm._s(_vm.curStep)+"/3")])]),_c('div',{staticClass:"divider"}),(_vm.curStep === 1)?_c('div',[_c('p',{staticClass:"font-bold text-xl pb-[20px]"},[_vm._v("您希望達成怎麼樣的目標？")]),_c('div',{staticClass:"flex items-center flex-wrap",staticStyle:{"gap":"16px"}},_vm._l((_vm.targets),function(option){return _c('div',{key:option.label,staticClass:"target-option-item",class:{
            active: _vm.formData.target === option.value,
          },on:{"click":function($event){return _vm.onTargetChange(option.value)}}},[_c('span',{staticClass:"font-medium"},[_vm._v(_vm._s(option.label))])])}),0)]):_vm._e(),(_vm.curStep === 1 && _vm.formData.target === '增加業績')?_c('div',{staticStyle:{"margin-top":"48px"}},[_c('p',{staticClass:"font-bold text-xl pb-[20px]"},[_vm._v("請選擇 AI 智能分群標籤")]),_c('div',{staticClass:"flex items-center flex-wrap",staticStyle:{"gap":"16px"}},_vm._l((_vm.aiTags),function(tag){return _c('div',{key:tag.id,staticClass:"tag-option-item",class:{
            active: _vm.formData.tag === tag.name,
          },on:{"click":function($event){return _vm.onTagChange(tag.name)}}},[_vm._v(" "+_vm._s(tag.name)+"（"+_vm._s(tag.memberCount)+" 位） ")])}),0)]):_vm._e(),(_vm.curStep === 2)?_c('div',[_c('p',{staticClass:"font-bold text-xl pb-[20px]"},[_vm._v("AI 已根據您選擇的目標，產生對應的行銷策略，請選擇喜歡的行銷策略：")]),_c('div',{staticClass:"flex flex-wrap",staticStyle:{"gap":"20px"}},_vm._l((_vm.strategys),function(strategy){return _c('BeautyAIEventTemplateCard',{directives:[{name:"show",rawName:"v-show",value:(strategy.targets.includes(_vm.formData.target)),expression:"strategy.targets.includes(formData.target)"}],key:strategy.title,staticClass:"relative",class:{
            'card-selected': _vm.formData.strategy === strategy.title,
          },attrs:{"data":{
            img: strategy.img,
            title: strategy.title,
            description: strategy.description,
          }},nativeOn:{"click":function($event){_vm.formData.strategy = strategy.title}}})}),1)]):_vm._e(),(_vm.curStep === 3)?_c('BeautyAIEventTemplateGenerateResult',{attrs:{"contextData":_vm.formData}}):_vm._e()],1),_c('PageFixedFooter',[(_vm.curStep > 1)?_c('el-button',{staticClass:"btn",attrs:{"plain":""},on:{"click":_vm.preStep}},[_vm._v("上一步")]):_vm._e(),(_vm.curStep < 3)?_c('el-button',{staticClass:"btn",attrs:{"plain":"","disabled":_vm.disabledNext,"loading":_vm.loading},on:{"click":_vm.nextStep}},[_vm._v(" 下一步 ")]):_vm._e(),(_vm.curStep === 3)?_c('el-button',{staticClass:"btn",attrs:{"plain":""},on:{"click":_vm.restart}},[_vm._v("重新開始")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }